import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, take, tap } from 'rxjs';
import { User } from '@shared/interfaces';
import { TokenService } from '@Services/token/token.service';
import { Router } from '@angular/router';
import { UserService } from '@Services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  /**
   * get redirection URL for SSO
   * @returns
   */
  redirectToMicrosoftSSO(): Observable<{ redirection_url: string }> {
    return this.http.get(`saml/login/`) as Observable<{
      redirection_url: string;
    }>;
  }

  /**
   * Service to verify SSO toke and get user details
   * @param path
   * @returns
   */
  authenticateSsoToken(path: string): Observable<User> {
    return this.http.get(`saml/acs${path}`) as Observable<User>;
  }

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`saml/logout/`, {})
        .pipe(
          take(1),
          tap(() => {
            this.tokenService.removeAuthTokens();
            this.userService.clearUserData();
            this.router.navigate(['/auth/login']);
            resolve();
          }),
          catchError((error: Error) => {
            reject(error);
            return [];
          }),
        )
        .subscribe();
    });
  }
}
